<template>
  <div id="footer-container" class="d-flex flex-row flex-nowrap justify-space-between align-end">
    <div v-if="quote && $vuetify.breakpoint.width > 350" class="flex-grow-1">
      <div id="footer-quote">{{ $t('quote.text') }}</div>
      <div id="footer-author">{{ $t('quote.author') }}</div>
    </div>
    <div v-else class="flex-grow-1"></div>
    <v-icon v-if="edit" class="mr-3 mb-3" size="40" color="primary" style="cursor: pointer" @click="onEdit">
      mdi-pencil-circle
    </v-icon>
    <v-icon v-if="help" class="mr-3 mb-3" size="40" color="primary" style="cursor: pointer" @click="onHelp">
      mdi-help-circle
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    quote: {type: Boolean, required: false, default: false},
    edit: {type: Boolean, required: false, default: false},
    onEdit: {type: Function, required: false, default: () => ({})},
    help: {type: Boolean, required: false, default: false},
    onHelp: {type: Function, required: false, default: () => ({})}
  }
};
</script>

<style scoped>
#footer-container {
  bottom: 0;
  color: #336799;
  position: absolute;
  width: 100%;
}

#footer-quote {
  font-size: 18px;
  font-style: italic;
  padding: 0 24px;
  text-indent: -8px;
}

#footer-author {
  font-size: 18px;
  font-weight: 500;
  padding: 0 24px 8px 24px;
}
</style>
