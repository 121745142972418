<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('events.' + $props.event)" :description="'-'"/>

    <!-- Description -->
    <div id="main-create-game-description">{{ description }}</div>

    <!-- Params -->
    <GameParamInput v-for="p in options.main" :key="p.id" :name="p.name" :id="p.id" :type="p.type"
                    :params="p.params" :onChange="onParamChange" :value="preferences[$props.event][p.id]"/>

    <!-- Custom params -->
    <div v-if="custom">
      <GameParamInput v-for="p in options.custom" :key="p.id" :name="p.name" :id="p.id" :type="p.type"
                      :params="p.params" :onChange="onParamChange" :value="preferences[$props.event][p.id]"/>
    </div>

    <!-- Start button -->
    <v-btn x-large class="ma-6 rounded-lg" color="primary" style="font-size: 20px; z-index: 2" @click="start()">
      START
    </v-btn>

    <!-- Footer -->
    <Footer :edit="options.hasOwnProperty('custom')" :on-edit="changeCustom"
            :help="$events.includes($props.event)" :on-help="showHelp"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';
import GameParamInput from '../components/GameParamInput';
import Footer from '../components/Footer';
import config from '../../../data/config.json';

export default {
  name: 'MainCreateGame',
  components: {Header, GameParamInput, Footer},
  props: {
    event: {type: String, required: true}
  },
  data: () => ({
    custom: false, userPreferences: {}, preferencesRefreshKey: 0, params: {
      'dates': {
        main: [
          {name: 'dates-highlight', id: 'highlight', type: 'switch', params: {values: []}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'dates-number', id: 'number', type: 'number', params: {min: 1, max: 240}}
        ]
      },
      'images': {
        main: [
          {name: 'images-highlight', id: 'highlight', type: 'switch', params: {values: []}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'images-number', id: 'number', type: 'number', params: {min: 1, max: 30}}
        ]
      },
      'imaginary-words': {
        main: [
          {name: 'imaginary-words-highlight', id: 'highlight', type: 'switch', params: {values: []}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'imaginary-words-number', id: 'number', type: 'number', params: {min: 1, max: 120}}
        ]
      },
      'names': {
        main: [
          {name: 'names-highlight', id: 'highlight', type: 'switch', params: {values: []}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'names-number', id: 'number', type: 'number', params: {min: 1, max: 128}}
        ]
      },
      'numbers': {
        main: [
          {name: 'numbers-highlight', id: 'highlight', type: 'switch', params: {values: [1, 2, 3, 4, 6]}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'numbers-number', id: 'number', type: 'number', params: {min: 1, max: 760}}
        ]
      },
      'words': {
        main: [
          {name: 'words-highlight', id: 'highlight', type: 'switch', params: {values: [1, 2, 3, 4]}}
        ],
        custom: [
          {name: 'memo-time', id: 'memo', type: 'number', params: {min: 1, max: 1800}},
          {name: 'recall-time', id: 'recall', type: 'number', params: {min: 1, max: 1800}},
          {name: 'words-number', id: 'number', type: 'number', params: {min: 1, max: 336}}
        ]
      },
      'addition': {
        main: [
          {name: 'addition-range', id: 'range', type: 'number', params: {min: 1, max: 1000}},
          {name: 'addition-time', id: 'time', type: 'number', params: {min: 1, max: 10}},
          {name: 'addition-number', id: 'number', type: 'number', params: {min: 2, max: 100}}
        ]
      },
      'colors': {
        main: []
      },
      'sequence': {
        main: []
      },
      'shapes': {
        main: [
          {name: 'shapes-time', id: 'time', type: 'number', params: {min: 1, max: 30}},
          {name: 'shapes-shape', id: 'shape', type: 'select', params: {values: ['koło', 'trójkąt', 'kwadrat']}},
          {name: 'shapes-color', id: 'color', type: 'select', params: {values: ['czerwony', 'zielony', 'niebieski']}}
        ]
      },
    }
  }),
  computed: {
    description() {
      let description = this.$t('descriptions.' + this.$props.event);
      if (description.includes('<memo>'))
        description = description.replace('<memo>', this.parseTimeToFriendly(config.events[this.$props.event].default.memo, true));
      return description;
    },
    options() {
      return this.params[this.$props.event];
    },
    preferences() {
      let preferences = {
        '_': this.preferencesRefreshKey,
        'dates': {
          highlight: 0,
          memo: config.events['dates'].default.memo,
          recall: config.events['dates'].default.recall,
          number: config.events['dates'].default.number
        },
        'images': {
          highlight: 0,
          memo: config.events['images'].default.memo,
          recall: config.events['images'].default.recall,
          number: config.events['images'].default.number
        },
        'imaginary-words': {
          highlight: 0,
          memo: config.events['imaginary-words'].default.memo,
          recall: config.events['imaginary-words'].default.recall,
          number: config.events['imaginary-words'].default.number
        },
        'names': {
          highlight: 0,
          memo: config.events['names'].default.memo,
          recall: config.events['names'].default.recall,
          number: config.events['names'].default.number
        },
        'numbers': {
          highlight: 0,
          memo: config.events['numbers'].default.memo,
          recall: config.events['numbers'].default.recall,
          number: config.events['numbers'].default.number
        },
        'words': {
          highlight: 0,
          memo: config.events['words'].default.memo,
          recall: config.events['words'].default.recall,
          number: config.events['words'].default.number
        },
        'addition': {range: 10, time: 1, number: 3},
        'colors': {},
        'sequence': {},
        'shapes': {time: 3, shape: 'koło', color: 'czerwony'}
      };
      let val;
      if (['0', '1'].includes(localStorage.getItem('preferences.dates.highlight')))
        preferences['dates'].highlight = parseInt(localStorage.getItem('preferences.dates.highlight'));
      if (['0', '1'].includes(localStorage.getItem('preferences.images.highlight')))
        preferences['images'].highlight = parseInt(localStorage.getItem('preferences.images.highlight'));
      if (['0', '1'].includes(localStorage.getItem('preferences.imaginary-words.highlight')))
        preferences['imaginary-words'].highlight = parseInt(localStorage.getItem('preferences.imaginary-words.highlight'));
      if (['0', '1'].includes(localStorage.getItem('preferences.names.highlight')))
        preferences['names'].highlight = parseInt(localStorage.getItem('preferences.names.highlight'));
      if (['0', '1', '2', '3', '4', '6'].includes(localStorage.getItem('preferences.numbers.highlight')))
        preferences['numbers'].highlight = parseInt(localStorage.getItem('preferences.numbers.highlight'));
      if (['0', '1', '2', '3', '4'].includes(localStorage.getItem('preferences.words.highlight')))
        preferences['words'].highlight = parseInt(localStorage.getItem('preferences.words.highlight'));
      val = parseInt(localStorage.getItem('preferences.dates.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['dates'].memo = val;
      val = parseInt(localStorage.getItem('preferences.images.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['images'].recall = val;
      val = parseInt(localStorage.getItem('preferences.imaginary-words.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['imaginary-words'].memo = val;
      val = parseInt(localStorage.getItem('preferences.names.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['names'].memo = val;
      val = parseInt(localStorage.getItem('preferences.numbers.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['numbers'].memo = val;
      val = parseInt(localStorage.getItem('preferences.words.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['words'].memo = val;
      val = parseInt(localStorage.getItem('preferences.dates.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['dates'].recall = val;
      val = parseInt(localStorage.getItem('preferences.images.memo'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['images'].memo = val;
      val = parseInt(localStorage.getItem('preferences.imaginary-words.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['imaginary-words'].recall = val;
      val = parseInt(localStorage.getItem('preferences.names.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['names'].recall = val;
      val = parseInt(localStorage.getItem('preferences.numbers.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['numbers'].recall = val;
      val = parseInt(localStorage.getItem('preferences.words.recall'));
      if (!isNaN(val) && val >= 1 && val <= 1800) preferences['words'].recall = val;
      val = parseInt(localStorage.getItem('preferences.dates.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['dates']) preferences['dates'].number = val;
      val = parseInt(localStorage.getItem('preferences.images.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['images']) preferences['images'].number = val;
      val = parseInt(localStorage.getItem('preferences.imaginary-words.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['imaginary-words']) preferences['imaginary-words'].number = val;
      val = parseInt(localStorage.getItem('preferences.names.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['names']) preferences['names'].number = val;
      val = parseInt(localStorage.getItem('preferences.numbers.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['numbers']) preferences['numbers'].number = val;
      val = parseInt(localStorage.getItem('preferences.words.number'));
      if (!isNaN(val) && val >= 1 && val <= this.$top['words']) preferences['words'].number = val;
      val = parseInt(localStorage.getItem('preferences.addition.range'));
      if (!isNaN(val) && val >= 1 && val <= 1000) preferences['addition'].range = val;
      val = parseInt(localStorage.getItem('preferences.addition.time'));
      if (!isNaN(val) && val >= 1 && val <= 10) preferences['addition'].time = val;
      val = parseInt(localStorage.getItem('preferences.addition.number'));
      if (!isNaN(val) && val >= 1 && val <= 100) preferences['addition'].number = val;
      return preferences;
    }
  },
  mounted() {
    this.userPreferences = JSON.parse(JSON.stringify(this.preferences[this.$props.event]));
    if (this.$events.includes(this.$props.event)) {
      this.userPreferences.memo = undefined;
      this.userPreferences.recall = undefined;
      this.userPreferences.number = undefined;
    }
  },
  methods: {
    changeCustom() {
      this.custom = !this.custom;
      if (this.custom === false) {
        this.userPreferences.memo = undefined;
        this.userPreferences.recall = undefined;
        this.userPreferences.number = undefined;
      } else if (this.$events.includes(this.$props.event)) {
        this.userPreferences.memo = this.preferences[this.$props.event].memo;
        this.userPreferences.recall = this.preferences[this.$props.event].recall;
        this.userPreferences.number = this.preferences[this.$props.event].number;
      }
    },
    onParamChange(id, value) {
      localStorage.setItem('preferences.' + this.$props.event + '.' + id, value);
      this.userPreferences[id] = value;
      this.preferencesRefreshKey = Math.random();
    },
    parseTimeToFriendly(time, transform = false) {
      let m = Math.floor(time / 60);
      let s = time % 60;
      if (transform) {
        if (m === 0) return this.$tc('help.seconds-transform', s);
        else if (s === 0) return this.$tc('help.minutes-transform', m);
        else return this.$tc('help.minutes-transform', m) + ' ' + this.$t('common.and') + ' ' + this.$tc('help.seconds-transform', s);
      } else {
        if (m === 0) return this.$tc('help.seconds', s);
        else if (s === 0) return this.$tc('help.minutes', m);
        else return this.$tc('help.minutes', m) + ' ' + this.$tc('help.seconds', s);
      }
    },
    showHelp() {
      let title = this.$t('events.' + this.$props.event).toUpperCase();
      let sections = [];
      sections.push({
        title: this.$t('help.goal'),
        icon: 'mdi-flag',
        content: this.$t('help.' + this.$props.event + '.goal')
      });
      let memoTime = this.$t('help.time-memo') + ': ' + this.parseTimeToFriendly(config.events[this.$props.event].default.memo);
      let recallTime = this.$t('help.time-recall') + ': ' + this.parseTimeToFriendly(config.events[this.$props.event].default.recall);
      let memoHint = this.$t('help.common-memo-hint').replace('<hint>', this.$t('help.' + this.$props.event + '.memo-hint'));
      sections.push({
        title: this.$t('help.time'),
        icon: 'mdi-timer',
        content: memoTime + '\n' + recallTime + '\n' + memoHint
      });
      sections.push({
        title: this.$t('help.scoring'),
        icon: 'mdi-trophy',
        content: this.$t('help.' + this.$props.event + '.scoring')
      });
      sections.push({
        title: this.$t('help.settings'),
        icon: 'mdi-cog',
        content: this.$t('help.common-settings')
      });
      this.$store.commit('showAdvancedDialog', {title, sections});
    },
    start() {
      this.$api.createGame(this.$props.event, this.userPreferences).then(() => {
        if (this.$events.includes(this.$props.event)) {
          this.$store.commit('setLocation', '/training/' + this.$props.event);
          this.$router.replace('/play/training/' + this.$props.event);
        } else {
          this.$store.commit('setLocation', '/wamp-up/' + this.$props.event);
          this.$router.replace('/play/warm-up/' + this.$props.event);
        }
      }).catch((err) => {
        if (err.message && err.message === 'invalid-values') {
          this.$store.commit('showSnackbar', this.$t('game-creator.invalid-values'));
        } else if (!err.response || err.response.status !== 403)
          this.$store.commit('showSnackbar', this.$t('common.error'));
        else this.$store.commit('showSnackbar', this.$t('common.no-access'));
      });
    }
  }
}
</script>

<style scoped>
#main-create-game-description {
  color: #336799;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 8px;
  padding: 24px;
}
</style>
