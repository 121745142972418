<template>
  <div id="game-param-input-container" class="d-flex flex-row flex-wrap justify-start align-stretch">
    <div id="game-param-input-name">{{ $t('game-creator.' + name) }}:</div>
    <div class="game-param-input-box" v-if="type === 'switch'">
      <v-switch inset v-model="primaryValue"
                @change="updateSecondaryValueForSwitch(primaryValue); sendChange(primaryValue, secondaryValue)"/>
      <div style="width: 37px" v-if="params.values.length !== 0">
        <v-select dense outlined hide-details :items="params.values" append-icon="" :disabled="!primaryValue"
                  v-model="secondaryValue" @change="sendChange(primaryValue, secondaryValue)"/>
      </div>
    </div>
    <div class="game-param-input-box" v-else-if="type === 'number'">
      <div style="width: 95px">
        <v-text-field dense outlined hide-details type="number" :rules="[numberRules]"
                      v-model="primaryValue" @input="sendChange(primaryValue, secondaryValue)"/>
      </div>
    </div>
    <div class="game-param-input-box" v-else-if="type === 'select'">
      <div style="width: 140px">
        <v-select dense outlined hide-details :items="params.values"
                  v-model="primaryValue" @input="sendChange(primaryValue, secondaryValue)"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameParamInput',
  props: {
    name: {type: String, required: true},
    id: {type: String, required: true},
    type: {type: String, required: true},
    value: {type: Number || String, required: true},
    params: {type: Object, required: true},
    onChange: {type: Function, required: false, default: () => ({})}
  },
  data: () => ({
    primaryValue: null,
    secondaryValue: null
  }),
  created() {
    if (this.type === 'switch') {
      this.primaryValue = this.value > 0;
      this.secondaryValue = this.value;
    } else if (this.type === 'number') {
      this.primaryValue = this.value;
    } else if (this.type === 'select') {
      this.primaryValue = this.value;
    }
  },
  methods: {
    numberRules(value) {
      let v = parseInt(value.toString().replaceAll(' ', ''));
      return Math.round(v) === v && v >= this.params.min && v <= this.params.max;
    },
    sendChange(primaryValue, secondaryValue) {
      if (this.type === 'switch') {
        if (this.params.values.length === 0) this.onChange(this.id, primaryValue ? 1 : 0);
        else this.onChange(this.id, primaryValue ? secondaryValue : 0);
      } else if (this.type === 'number') {
        let value = isNaN(primaryValue.toString().replaceAll(' ', '')) ? 0 : primaryValue.toString().replaceAll(' ', '');
        this.onChange(this.id, parseInt(value));
      } else if (this.type === 'select') {
        this.onChange(this.id, primaryValue);
      }
    },
    updateSecondaryValueForSwitch(primaryValue) {
      if (primaryValue) this.secondaryValue = 1;
      else this.secondaryValue = 0;
    }
  }
}
</script>

<style scoped>
#game-param-input-container {
  height: 50px;
  margin-bottom: 20px;
}

#game-param-input-name {
  align-items: center;
  color: #336799;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  padding-left: 24px;
  width: 280px;
}

.game-param-input-box {
  align-items: center;
  display: flex;
  height: 50px;
  padding-left: 28px;
  width: 280px;
}

/*noinspection CssUnusedSymbol*/
.v-select__selection {
  justify-content: center;
  width: 100%;
}

@media (max-width: 700px) {
  #game-param-input-container {
    height: auto;
  }
}
</style>
